<template>
    <div>
        <b-carousel-list v-model="slide" :data="data" :items-to-show="windowWidth < 600 ? 1.5 : 1">
            <template slot="item" slot-scope="list">
                <icon-text-component :item="list" />
            </template>
        </b-carousel-list>
    </div>
</template>
<script>
export default {
    components: {
        IconTextComponent: () => import('./IconTextComponent')
    },
    props: {
        data: {
            type: Array
        }
    },
    data () {
        return {
            slide: 0
        }
    }
}
</script>
